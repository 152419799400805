<!-- 项目详情页面 -->
<template>
  <div class="projectDetail">
    <my-banner :banner="banner" />
    <div class="main">
      <div class="img">
        <img :src="data.noticePicture" :key="data.noticePicture" alt="" />
      </div>
      <div class="content">
        <div class="title">{{ data.noticeTitle }}</div>
        <div class="subtitle">{{ data.noticeSubtitle }}</div>
        <div class="text_content text" v-html="data.noticeContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetails, queryPhotoManageList } from "@/api/api.js";
export default {
  name: "projectDetail",
  data() {
    return {
      banner: "",
      data: {}, //详情
    };
  },
  created() {
    getDetails({
      id: this.$route.query.noticeId,
    }).then((res) => {
      this.data = res.data.data;
    });

    queryPhotoManageList({
      photoPage: "服务项目",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
  methods: {},
};
</script>
<style lang='less' scoped>
@media screen and(max-width: 1200px) {
  .projectDetail {
    .main {
      width: 80% !important;
    }
  }
}
@media screen and(max-width: 988px) {
  .projectDetail {
    .main {
      width: 90% !important;
    }
  }
}
@media screen and(max-width: 780px) {
  .projectDetail {
    .main {
      width: 90% !important;
      > div {
        width: 100% !important;
      }
      .content {
        margin-top: 30px;
        margin-left: 0 !important;
      }
    }
  }
}
.projectDetail {
  .main {
    max-width: 1100px;
    width: 60%;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    .img {
      width: 40%;
      img {
        width: 100%;
      }
    }
    .content {
      flex: 1;
      margin-left: 20px;
      .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
      }
      .subtitle {
        margin-top: 10px;
        color: #454545;
        font-size: 12px;
      }
      .text_content {
        /deep/ * {
          font-size: 14px !important;
          color: #333333 !important;
        }
      }
      > div:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}
</style>